import { Grid } from "@mui/material";
import React from "react";
import UpdatedComponent from "../../components/generalBanner/BannerHOC";
import banner_img from "../../assets/banners/banner_design3.jpg";
import BannerComponent from "../../components/generalBanner/BannerComponent";

const AndroidDevMainPage = () => {
  return (
    <div style={{ height: "2500px" }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        columnSpacing={{ xs: 1, sm: 1, md: 5 }}
        rowSpacing={1}
        justifyContent="center"
        alignItems="center"
        // className="section-container"
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BannerComponent banner_img={banner_img} />
        </Grid>
      </Grid>
    </div>
  );
};

export default AndroidDevMainPage;
