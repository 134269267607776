import React from "react";
import { Grid } from "@mui/material";

import "./css/banner_component.css";
import ButtonReusable from "../buttons/ButtonReusable";
import PulseIconButton from "../buttons/PulseIconButton";

import pocket_hr_device from "../../assets/gallery/pockethr/pocker_hr_device.png";

import banner from "../../assets/banners/banner_design3.jpg";

const BannerComponent = (props) => {
  return (
    <div style={{ height: "2500px" }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        columnSpacing={{ xs: 1, sm: 1, md: 5 }}
        rowSpacing={1}
        justifyContent="center"
        alignItems="center"
        className="std-main-banner-container"

        // className="section-container"
      >
        {/* <img src={banner} alt="" className="banner-img" /> */}
        <Grid item xs={12} sm={12} md={12} lg={9}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            columnSpacing={{ xs: 1, sm: 1, md: 5 }}
            rowSpacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className="std-left-container"
            >
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                columnSpacing={{ xs: 1, sm: 1, md: 5 }}
                rowSpacing={3}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <p className=""> Simplify HR Management</p>
                  <h1>
                    Unlock Potentials, Anytime, Anywhere <br /> HR Management At
                    Your Fingertips
                  </h1>
                  <p>
                    Instant access, enduring results: Transform your workplace
                    with our intuitive HR Management at Your Fingertips,
                    offering an efficient and effective way to navigate the
                    intricacies of talent management.
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                    columnSpacing={{ xs: 1, sm: 1, md: 5 }}
                    rowSpacing={1}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <ButtonReusable
                        buttonName="Check the App Now"
                        backgroundColor="#063c41"
                        color="white"
                        padding="16px 32px"
                        borderRadius="50px"
                        fontSize="12px"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      className="watch-demo-container"
                    >
                      <PulseIconButton />

                      <p>Watch Demo Now</p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className="std-right-container"
            >
              <div className="std-img-container">
                <img src={pocket_hr_device} alt="" className="img" />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default BannerComponent;
