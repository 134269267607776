import { Grid } from "@mui/material";
import React, { useState } from "react";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { IoReceipt } from "react-icons/io5";
import { FaMapMarkedAlt } from "react-icons/fa";
import { BsPersonBoundingBox } from "react-icons/bs";

import customer_management_video from "../../assets/gallery/ums/CUSTOMER.mp4";
import collection_management_video from "../../assets/gallery/ums/COLLECTION.mp4";
import billing_management_video from "../../assets/gallery/ums/BILLING.mp4";
import fieldr_management_video from "../../assets/gallery/ums/FIELD.mp4";
// import customer_management_video from "../../assets/gallery/ums/CUSTOMER.mp4";

import ums_video from "../../assets/gallery/ums/ums_video.mp4";
const UMSModules = () => {
  const module_details = [
    {
      img: customer_management_video,
      name: "Customer Management System",
      desc: "Effectively managing data to ensure customer satisfaction",
      icon: <BsPersonBoundingBox />,
    },
    {
      img: collection_management_video,
      name: "Collection Management System",
      desc: "We simplifies and optimizes your collection management proces",
      icon: <FaFileInvoiceDollar />,
    },
    {
      img: billing_management_video,
      name: "Billing Management System",
      desc: "Experience hassle-free billing with our intuitive Billing management system",
      icon: <IoReceipt />,
    },
    {
      img: fieldr_management_video,
      name: "Field Management System",
      desc: "Realtime tracking, insightful analysis, and comprehensive reportin",
      icon: <FaMapMarkedAlt />,
    },
  ];
  return (
    <Grid
      container
      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
      columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
      rowSpacing={1}
      justifyContent="center"
    >
      <Grid item xs={12} sm={12} md={9} lg={9}>
        <div className="module-card-container">
          <p className="tagline-text">
            Utility Management System (UMS) Modules
          </p>
          <p className="text">
            Our comprehensive system is designed with multiple modules that
            cater to the diverse needs and concerns of our clients, providing
            effective solutions{" "}
          </p>
          <Grid
            container
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            columnSpacing={{ xs: 1, sm: 1, md: 3, lg: 3 }}
            rowSpacing={1}
            justifyContent="center"
          >
            {module_details.map((val, index) => (
              <Grid item xs={12} sm={12} md={3} lg={3} key={index}>
                <div className="module-card">
                  <div className="module-img-container">
                    <div className="container">
                      <video
                        // controls
                        autoPlay
                        loop
                        muted
                        src={val.img}
                        type="video/mp4"
                        width="100%"
                        height="100%"
                      ></video>
                    </div>
                  </div>
                  <div className="module-name">
                    <h4>{val.name}</h4>
                  </div>
                  <div className="module-desc">
                    <p>{val.desc}</p>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default UMSModules;
