import React, { Suspense, lazy } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  HashRouter,
} from "react-router-dom";
import "../src/App.css";
import UMSpage from "./pages/umsPage/UMSpage";
import LoadingAnimation from "./components/loadingAnimation/LoadingAnimation";
import DataStructure from "./pages/datastructure/DataStructure";
import AndroidDevMainPage from "./pages/androidDevPage/AndroidDevMainPage";

const HomePage = lazy(() => import("./pages/homepage/HomePage"));
const Navigationbar = lazy(() => import("./components/navigation/NavBar"));

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Suspense>
          <Routes>
            <Route path="/" element={<Navigationbar />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/umspage" element={<UMSpage />} />
              <Route path="/loading" element={<LoadingAnimation />} />
              <Route path="/android_dev" element={<AndroidDevMainPage />} />
            </Route>
            <Route path="/data" element={<DataStructure />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </div>
  );
}

export default App;
