import DataStructureHooks from "./hooks/DataStructureHooks";
import TextFieldReusable from "../../components/textfields/TextFieldReusable";
import ButtonReusable from "../../components/buttons/ButtonReusable";
import img_reverse from "../../assets/gallery/cctv_uniview3_rev.jpg";
import inverted_meter from "../../assets/gallery/inverted_meter.jpg";

import React, { useEffect, useRef } from "react";
import Exif from "exif-js";

const DataStructure = ({ imageUrl }) => {
  const imgRef = useRef();

  useEffect(() => {
    const img = imgRef.current;

    if (img.complete) {
      handleImageLoad();
    } else {
      img.onload = handleImageLoad;
    }

    return () => {
      img.onload = null;
    };
  }, [imageUrl]);

  const handleImageLoad = () => {
    console.log("Image loaded successfully");
    const img = imgRef.current;

    Exif.getData(img, function () {
      const orientation = Exif.getTag(this, "Orientation");

      // Apply transformation based on the orientation
      switch (orientation) {
        case 2:
          img.style.transform = "scaleX(-1)";
          break;
        case 3:
          img.style.transform = "rotate(180deg)";
          break;
        case 4:
          img.style.transform = "scaleY(-1)";
          break;
        case 5:
          img.style.transform = "rotate(-90deg) scaleX(-1)";
          break;
        case 6:
          img.style.transform = "rotate(90deg)";
          break;
        case 7:
          img.style.transform = "rotate(90deg) scaleY(-1)";
          break;
        case 8:
          img.style.transform = "rotate(-90deg)";
          break;
        default:
          // No transformation needed for orientation 1 (normal)
          break;
      }
    });
  };
  return (
    <div style={{ width: "400px", height: "300px" }}>
      <img
        ref={imgRef}
        src={inverted_meter}
        alt="Your Image"
        style={{ height: "100%", width: "100%" }}
      />
    </div>
  );
};

export default DataStructure;
