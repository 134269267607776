// gzonetech clients
// import ileco from "../../assets/clients/ileco_logo.png";
// import meralco from "../../assets/clients/meralco.png";
// import mmsc from "../../assets/clients/mmsc.png";
// import baliwag from "../../assets/clients/baliwag.png";

// ums utils
import ums1 from "../../assets/gallery/ums/bannerImage.png";
import ums2 from "../../assets/gallery/ums/dashboard1.png";
import ums3 from "../../assets/gallery/ums/dashboard2.png";

// other services card images

import discon from "../../assets/gallery/other_services/discon.jpg";
import recon from "../../assets/gallery/other_services/recon.jpg";
import read_and_bill from "../../assets/gallery/other_services/read_and_bill.jpg";
import leak_detection from "../../assets/gallery/other_services/leak_detection.jpg";
import spot_billing from "../../assets/gallery/other_services/spot_billing.jpg";
import third_party_audit from "../../assets/gallery/other_services/third_party_audit.jpg";

import { PiPlugsConnectedLight } from "react-icons/pi";
import { VscDebugDisconnect } from "react-icons/vsc";
import { IoReceiptOutline } from "react-icons/io5";
import { IoBarChartSharp } from "react-icons/io5";
import { TbListSearch } from "react-icons/tb";

import { PiPlugChargingLight } from "react-icons/pi";
import { CiReceipt } from "react-icons/ci";
import { HiOutlineViewfinderCircle } from "react-icons/hi2";
import { IoPrintOutline } from "react-icons/io5";
import { BsGraphUpArrow } from "react-icons/bs";

import meter from "../../assets/water_meter_reading.png";

// products images list
import thermal_paper from "../../assets/gallery/products/thermal_paper.webp";
import mobile_printer_honeywell from "../../assets/gallery/products/mobile_printer_honeywell.webp";
import thermal_printer from "../../assets/gallery/products/thermal_printer.jpg";
import thermal_paper_printer from "../../assets/gallery/products/thermal_paper_printer.jpg";
import cctv_uniview1 from "../../assets/gallery/products/cctv_uniview1.jpg";
import cctv_uniview2 from "../../assets/gallery/products/cctv_uniview2.jpg";
import cctv_uniview3 from "../../assets/gallery/products/cctv_uniview3.jpg";

// client images
import alway from "../../assets/gallery/clients/always.png";
import batelec from "../../assets/gallery/clients/baletec.png";
import clarkl_electric from "../../assets/gallery/clients/clark_electric.png";
import cozy from "../../assets/gallery/clients/cozy.png";
import mmsc from "../../assets/gallery/clients/mmsc.png";
import greenzone from "../../assets/gallery/clients/greenzone.png";
import ngc_enterprise from "../../assets/gallery/clients/ngc_enterprice.png";
import ngc_express from "../../assets/gallery/clients/ngc_expres.png";
import usi from "../../assets/gallery/clients/usi.png";
import meralco_batangas from "../../assets/gallery/clients/meralco_batangas.png";
import meralco_ecozone from "../../assets/gallery/clients/meralco_ecozone.png";
import meralco_lucena from "../../assets/gallery/clients/meralco_lucena.png";
import use from "../../assets/gallery/clients/usi.png";

export const gallery_utils = [
  // { img: gallery1, title: "", desc: "" },
  // { img: gallery2, title: "", desc: "" },
  // { img: gallery3, title: "", desc: "" },
  // { img: gallery4, title: "", desc: "" },
  // { img: gallery5, title: "", desc: "" },
  // { img: gallery6, title: "", desc: "" },
  // { img: gallery7, title: "", desc: "" },
  // { img: gallery8, title: "", desc: "" },
  // { img: gallery9, title: "", desc: "" },
];

export const ums_gallery = [
  {
    img: <CiReceipt />,
    title: "Read and Bill",
    desc: "Accurate and complete reading of water and electric meters in volumes",
    media: read_and_bill,
  },

  {
    img: <PiPlugChargingLight />,
    title: "Disconnection",
    desc: "Accurate and complete reading of water and electric meters in volumes",
    media: discon,
  },

  {
    img: <PiPlugsConnectedLight />,
    title: "Reconnection",
    desc: "We train and provide field men to accomodate customer in restoring their connection",
    media: recon,
  },

  {
    img: <HiOutlineViewfinderCircle />,
    title: "Leak Repair",
    desc: "Accurate and complete reading of water and electric meters in volumes",
    media: leak_detection,
  },
  {
    img: <IoPrintOutline />,
    title: "Spot Billing",
    desc: "Accurate and complete reading of water and electric meters in volumes",
    media: spot_billing,
  },
  {
    img: <BsGraphUpArrow />,
    title: "Third Party Audit",
    desc: "Accurate and complete reading of water and electric meters in volumes",
    media: third_party_audit,
    // 13.755581, 121.071785
  },
];

// export const client_list = [
//   { img: meralco, name: "meralco" },
//   { img: baliwag, name: "baliwag" },
//   { img: ileco, name: "ileco" },
//   { img: mmsc, name: "mmsc" },
//   { img: meralco, name: "meralco" },
//   { img: baliwag, name: "baliwag" },
//   { img: ileco, name: "ileco" },
//   { img: mmsc, name: "mmsc" },
// ];

export const product_list = [
  { img: thermal_paper, name: "thermal_paper" },
  { img: thermal_printer, name: "thermal_printer" },
  { img: thermal_paper_printer, name: "thermal_paper_printer" },
  { img: cctv_uniview1, name: "cctv_uniview1" },
  { img: cctv_uniview2, name: "cctv_uniview2" },
  { img: cctv_uniview3, name: "cctv_uniview3" },
  { img: mobile_printer_honeywell, name: "mobile_printer_honeywell" },
];

// export const images = [image1, image2, image3, image4]

const imageByIndex = (index) => product_list[index % product_list.length];

export default imageByIndex;

export const clients = [
  { image: alway },
  { image: batelec },
  { image: clarkl_electric },
  { image: cozy },
  { image: mmsc },
  { image: greenzone },
  { image: ngc_enterprise },
  { image: ngc_express },
  { image: usi },
  { image: meralco_batangas },
  { image: meralco_ecozone },
  { image: meralco_lucena },
];
